import Axios from 'axios'
import SurveyUserModel from '../model/SurveyUserModel'
import SurveyUserRoleModel from '../model/SurveyUserRoleModel'
import { ApiUrl } from '../util/api.url'
import { CreateConceptSurveyRequestModel } from '../model/CreateConceptSurveyRequestModel'
import EditSurveyModel from '../model/EditSurveyModel'
import SurveyClassificationElementModel from '../model/SurveyClassificationElementModel'
import { BaseService } from './base.service'
import { SurveyModel } from '~/shared/model/SurveyModel'
import SurveyExportModel from '~/shared/model/SurveyExportModel'
import SurveyClassificationModel from '~/shared/model/SurveyClassificationModel'

export class SurveyService extends BaseService {
  private static baseUrl = ApiUrl.webapiurl() + '/Survey/'
  private static adagioExportUrl = ApiUrl.webapiurl() + '/ExportAdagio/'

  static async getAllSurveys (): Promise<Array<SurveyModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetAllSurveys')
    return response.data
  }

  static async getSurveyUsers (survey: SurveyModel): Promise<Array<SurveyUserModel>> {
    const response = await this.postApiCall(this.baseUrl + 'GetSurveyUsers', survey)
    return response.data
  }

  static async getSurveyUserRoles (user: SurveyUserModel): Promise<Array<SurveyUserRoleModel>> {
    const response = await this.postApiCall(this.baseUrl + 'GetSurveyUserRoles', user)
    return response.data
  }

  static async getAvailableUsers (): Promise<Array<SurveyUserModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetAvailableUsers')
    return response.data
  }

  static async getAvailableRoles (): Promise<Array<SurveyUserRoleModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetAvailableRoles')
    return response.data
  }

  static async addSurveyUserRole (role: SurveyUserRoleModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'AddSurveyUserRole', role)
    return response.data
  }

  static async addSurveyUser (user: SurveyUserModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'addSurveyUser', user)
    return response.data
  }

  static async deleteSurveyUser (user: SurveyUserModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'deleteSurveyUser', user)
    return response.data
  }

  static async deleteSurveyUserRole (role: SurveyUserRoleModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'deleteSurveyUserRole', role)
    return response.data
  }

  static async getMySurveys (): Promise<Array<SurveyModel>> {
    const response = await this.getApiCall(this.baseUrl + 'GetMySurveys')
    return response.data
  }

  static async createConceptSurvey (request: CreateConceptSurveyRequestModel): Promise<number[]> {
    const response = await this.postApiCall(this.baseUrl + 'CreateConceptSurvey', request)
    return response.data
  }

  static async getSurveyWithComponents (surveyId: number): Promise<EditSurveyModel> {
    const param = {
      surveyId: surveyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetSurveyWithComponents', param)
    return response.data
  }

  static async getSurveyExportDocumentBySurveyId (surveyId: number, metadata: boolean): Promise<any> {
    const param = {
      surveyId: surveyId,
      metadata: metadata
    }
    const response = await this.FileDownload(this.baseUrl + 'GetSurveyExportDocumentBySurveyId', param)
    return response.data
  }

  static async getQualtricsSurveyExportDocumentBySurveyId (surveyId: number): Promise<any> {
    const param = {
      surveyId: surveyId
    }
    const response = await this.FileDownload(this.baseUrl + 'GetQualtricsSurveyExportDocumentBySurveyId', param)
    return response.data
  }

  static async saveSurvey (model: EditSurveyModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'saveSurvey', model)
    return response.data
  }

  static async deleteSurvey (surveyId: number): Promise<void> {
    const param = {
      surveyId: surveyId
    }
    const response = await this.deleteApiCall(this.baseUrl + 'deleteSurvey', param)
    return response.data
  }

  static async saveSurveyClassification (surveyClassification: SurveyClassificationModel): Promise<void> {
    const response = await this.postApiCall(this.baseUrl + 'saveSurveyClassification', surveyClassification)
    return response.data
  }

  static async getSurveyClassifications (surveyId: number): Promise<Array<SurveyClassificationModel>> {
    const param = {
      surveyId: surveyId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetSurveyClassifications', param)
    return response.data
  }

  static async getSurveyClassificationElements (surveyClassificationId: number): Promise<Array<SurveyClassificationElementModel>> {
    const param = {
      surveyClassificationId: surveyClassificationId
    }
    const response = await this.getApiCallWithParam(this.baseUrl + 'GetSurveyClassificationElements', param)
    return response.data
  }

  static async exportSurveyAdagio (surveyId: number, datasetClassificationId: number): Promise<void> {
    const param = {
      surveyId,
      datasetClassificationId
    }
    const response = await this.getApiCallWithParam(this.adagioExportUrl + 'ExportSurvey', param)
    return response.data
  }

  static async UploadSurveyResponsesToDWH (file:any, surveyId:string): Promise<void> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('surveyId', surveyId)

    const response = await this.FileUpload(this.baseUrl + 'UploadSurveyResponsesToDWH', formData)
    return response.data
  }
}
